var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTView',[_c('VRow',[_c('VCol',[_c('VCard',[_c('VCardTitle',[_vm._v("Компании")]),_c('VDivider'),_c('VCardText',[_c('VDataTable',{attrs:{"items":_vm.companies,"headers":_vm.companyHeaders},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var ref_item = ref.item;
var id = ref_item.id;
var name = ref_item.name;
return [_c('RouterLink',{staticClass:"d-block",attrs:{"to":{
                  name : _vm.Names.R_ORGSTRUCTURE_COMPANY,
                  params : { id: id }
                }}},[_vm._v(" "+_vm._s(name)+" ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }