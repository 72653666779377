<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VCardTitle>Компании</VCardTitle>

          <VDivider />

          <VCardText>
            <VDataTable
              :items="companies"
              :headers="companyHeaders"
            >
              <template #item.name="{ item : { id, name } }">
                <RouterLink
                  class="d-block"
                  :to="{
                    name : Names.R_ORGSTRUCTURE_COMPANY,
                    params : { id }
                  }"
                >
                  {{ name }}
                </RouterLink>
              </template>
            </VDataTable>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'OrgMain',
  inject: ['Names'],
  data() {
    return {
      companies: [],
      companyHeaders: [
        {
          text: 'Название',
          value: 'name',
        },
      ],
    };
  },
  created() {
    this.$di.api.Account
      .indexCompany()
      .then((r) => {
        this.companies = r.companies;
      })
      .catch(this.$di.notify.errorHandler);
  },
};
</script>
